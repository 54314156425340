import { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrmbs } from "../comps/comps";
import { RefreshIconBtn } from "../comps/icon-btns";
import dataSource from "./../data/entitlements";

function EntitlementsPage() {
  const navigation = useNavigate();
  const inited = useRef(false);
  const [data, setData] = useState(dataSource.data);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!inited.current) {
      loadData();
    }
    inited.current = true;
  }, []);

  const loadData = async (p = 1) => {
    setIsLoading(true);
    const res = await dataSource.loadList();
    setData(res);
    setIsLoading(false);
  }

  return (
    <div>
      <div className="filterControls">
        <span>
          <Breadcrmbs currentTitle="Entitlements" />
        </span>
        <span>
          <RefreshIconBtn busy={isLoading} onRefresh={() => {
            dataSource.reset();
            loadData();
          }}/>
        </span>
      </div>
      <Container><Row><Col>
        <Table striped bordered>
          <thead>
            <tr>
                <th>Name</th>
                <th>Maxes</th>
                <th>Features</th>
                <th>Desc</th>
            </tr>
          </thead>
          <tbody>
            {data && data.map(item =>
              <tr key={item.id}>
                <td>
                  <li><Link to={`/entitlements/${item.id}`}>{item.name()}</Link></li>
                  <li>{item.name('ru')}</li>
                </td>
                <td>
                  {item.maxesStr?.map(val => <li key={val}>{val}</li>)}
                  {item.id === 'free' &&
                    <li style={{paddingTop: 8}}>
                      "rest": 10, "def": 20 (<small>For the customers registered after 24.06.2023</small>)
                    </li>
                  }
                </td>
                <td>{item.featEnabledNames?.map(d => <li key={d}>{d}</li>)}</td>
                <td>{item.desc()?.map(d => <li key={d}>{d}</li>)}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col></Row></Container>
    </div>
  );
}

export default EntitlementsPage;
