import api from "./../api/api";

class Entitlements {
  constructor() {
    this.data = [new Entitlement()];
    this.data.pop();
    this.byId = new Map();
    this.byId.set('dummy', new Entitlement());
    this.byId.clear();
  }

  reset() {
    this.data = [];
  }

  /**
   * 
   * @param {String} id 
   * @param {Customer} cust 
   * @returns {Entitlement}
   */
   itemForId(id, cust = null) {
    return cust?.preferedEnts?.getEntWithId(id) ?? this.byId.get(id);
  }

  async loadList() {
    try {
      let path = '/bof/entitlements';
      const resp = await api.inst.get(path);
      this.data = [];
      resp.data?.forEach(element => {
        const item = new Entitlement(element);
        this.data.push(item);
        this.byId.set(item.id, item);
      });
    } catch(e) {}
    
    const sortIndex = (id) => {
      if (id === 'min') return 0;
      if (id === 'free') return 10;
      if (id === 'basic') return 15;
      if (id === 'starter') return 20;
      if (id === 'advanced') return 30;
      if (id === 'pro') return 40;
      if (id === 'unlim') return 50;
      if (id === 'individual') return 51;
      if (id.startsWith('loc')) return 1000 + parseInt(id.split('loc')[1]);
      return 9999999;
    }
    this.data.sort((a, b) => sortIndex(a.id) - sortIndex(b.id));
    return this.data;
  }
}

export class Entitlement {
  constructor(json) {
    this.update(json);
  }

  update(json) { this.json = json ?? {}; }

  get id() { return this.json.id }
  name(lang) { return this.json['name' + (lang ? `_${lang}` : '')] }
  desc(lang) { return this.json['desc' + (lang ? `_${lang}` : '')] }

  get nameAll() {
    const ru = this.name('ru');
    let val = this.name();
    if (ru) val += ' / ' + ru;
    return val;
  }

  get maxes() { return this.json.maxes }
  get maxesStr() {
    const res = [];
    const maxes = this.maxes ?? {};
    const keys = Object.keys(maxes);
    for (let i = 0; i < keys.length; i++) {
      let val = maxes[keys[i]];
      if (typeof val === 'object') val = JSON.stringify(val, null, 1);
      res.push(keys[i] + ': ' + val);
    }
    return res;
  }

  get feats() { return this.json.feats }
  get featEnabledNames() {
    const res = [];
    const feats = this.feats ?? {};
    const keys = Object.keys(feats);
    for (let i = 0; i < keys.length; i++) {
      if (feats[keys[i]] && !keys[i].startsWith('__')) {
        res.push(keys[i]);
      }
    }
    return res;
  }
}

const data = new Entitlements();
export default data;


